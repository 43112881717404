import { range, debounce } from "lodash";

class Slider {
    constructor(args) {
        Object.assign(this, {
            wrapper: document.body,
            showNavArrows: true,
            ...args,
        });
        this.track = this.wrapper.querySelector(".js-track");
        this.sliderItems = this.wrapper.querySelectorAll(".js-slider-item");
        this.loader = this.wrapper.querySelector(".js-loader");
        this.sliderItemsLength = this.sliderItems ? this.sliderItems.length : 0;
        this.slideNumberOfElements = 0;
        this.pageNumber = 1;
        this.maxPageNumber = 1;
        this.activeItems = [];
        this.sliderWidth = 33.33;

        if (this.wrapper) {
            this._init();
        }
    }

    /**
     * Trigger all methods that are needed for initial load
     */
    _init() {
        this._setTrackSize();

        if (this.loader) this.loader.classList.add("hide");
        if (this.track) this.track.classList.remove("hide");

        this.slideNumberOfElements = this._setNumberOfElementsPerPage();

        this.activeItems = range(1, this.slideNumberOfElements + 1);

        this.maxPageNumber = this._getPageNumber();

        if (this.showNavArrows && this.sliderItems.length) {
            this._createNavigationArrows();
            this._disableNavigationArrows();
        }

        this._eventHandlers();
    }

    /**
     * Calculate how many elements there are per page
     */
    _setNumberOfElementsPerPage() {
        let numberOfElements = 1;

        if (this.sliderItems.length) {
            numberOfElements = Math.round(
                this.wrapper.clientWidth / this.sliderItems[0].clientWidth,
                10
            );

            if (!numberOfElements) {
                numberOfElements = 1;
            }
        }
        return numberOfElements;
    }

    /**
     * Create navigation arrows if needed
     */
    _createNavigationArrows() {
        const nextIcon = document.createElement("div");
        const prevIcon = document.createElement("div");

        nextIcon.className = "next-arrow js-nav-arrows";
        nextIcon.setAttribute("data-type", "next");

        prevIcon.className = "prev-arrow js-nav-arrows";
        prevIcon.setAttribute("data-type", "prev");

        this.wrapper.appendChild(nextIcon);
        this.wrapper.appendChild(prevIcon);
    }

    /**
     * Handle all events
     */
    _eventHandlers() {
        const scope = this;

        document.body.addEventListener("click", (e) => {
            const { target } = e;

            if (
                target.parentNode === scope.wrapper &&
                target.classList.contains("js-nav-arrows")
            ) {
                scope._slideCurrentElements(target.getAttribute("data-type"));
            }
        });

        window.addEventListener(
            "resize",
            debounce(() => {
                this._setTrackSize();

                if (
                    scope.slideNumberOfElements !==
                    scope._setNumberOfElementsPerPage()
                ) {
                    scope.slideNumberOfElements = scope._setNumberOfElementsPerPage();
                    scope.maxPageNumber = scope._getPageNumber();
                    scope.pageNumber = scope._getPageNumber(
                        scope.activeItems[0]
                    );

                    scope._seActiveElements();
                    scope._moveTrack();

                    if (scope.showNavArrows) {
                        scope._disableNavigationArrows();
                    }
                }
            }, 50)
        );
    }

    /**
     * Set track size base on the window size
     */
    _setTrackSize() {
        const bodyWidth = window.innerWidth;
        let slideTimes = this.sliderItems.length;

        if (bodyWidth <= 991 && bodyWidth > 575) {
            this.sliderWidth = 50;
            if (this.sliderItems.length < 3) {
                slideTimes = 2;
            }
        }

        if (bodyWidth <= 575) {
            this.sliderWidth = 100;
            if (this.sliderItems.length < 3) {
                slideTimes = this.sliderItems.length;
            }
        }

        if (bodyWidth > 991) {
            const withSize = 33.3;

            this.sliderWidth = withSize;
            if (this.sliderItems.length < 3) {
                slideTimes = 3;
            }
        }

        if (this.track) {
            this.track.style.width = this.sliderWidth * slideTimes + "%";
        }
    }

    /**
     * Method responsible for moving track by setting transform style
     */
    _moveTrack() {
        const slideWidth = 100 / this.sliderItemsLength;
        const activeElement = this.activeItems[0];

        if (this.track) {
            this.track.style.transform = `translateX(-${
                slideWidth * activeElement - slideWidth
            }%)`;
        }
    }

    /**
     * Get max page number or current active page
     * @param {boolean} activeSliderItem
     */
    _getPageNumber(activeSliderItem) {
        const slideItems = activeSliderItem || this.sliderItemsLength;
        let pageNumber = parseInt(slideItems / this.slideNumberOfElements, 10);

        if (slideItems >= this.slideNumberOfElements) {
            const numberFullPageRemaining = parseInt(
                slideItems % this.slideNumberOfElements,
                10
            );

            if (numberFullPageRemaining > 0) {
                pageNumber += 1;
            }
        } else {
            pageNumber = 1;
        }

        return pageNumber;
    }

    /**
     * Disable left arrow if page is 1 or next arrow if page is equal to maximum page number
     */
    _disableNavigationArrows() {
        const navArrows = this.wrapper.querySelectorAll(".js-nav-arrows");

        if (navArrows) {
            switch (this.pageNumber) {
                case this.maxPageNumber:
                    if (this.sliderItemsLength <= this.slideNumberOfElements) {
                        navArrows[0].classList.add("display-none");
                        navArrows[1].classList.add("display-none");
                    } else {
                        navArrows[0].classList.add("display-none");
                        navArrows[1].classList.remove("display-none");
                    }

                    break;
                case 1:
                    navArrows[1].classList.add("display-none");
                    navArrows[0].classList.remove("display-none");
                    break;
                default:
                    navArrows[0].classList.remove("display-none");
                    navArrows[1].classList.remove("display-none");
            }
        }
    }

    /**
     * Make range of current active elements on page
     */
    _seActiveElements() {
        const slideNumberPerPage = this.slideNumberOfElements * this.pageNumber;
        const rangeStart = slideNumberPerPage - this.slideNumberOfElements + 1;
        const rangeEnd = slideNumberPerPage + 1;
        const rangeValue = range(rangeStart, rangeEnd);

        this.activeItems = rangeValue;
    }

    /**
     * Method when you click on navigation arrow
     * @param {string} type
     */
    _slideCurrentElements(type) {
        if (type === "next" && this.maxPageNumber > this.pageNumber) {
            this.pageNumber += 1;
        }

        if (type === "prev" && this.pageNumber > 1) {
            this.pageNumber -= 1;
        }

        if (this.showNavArrows) {
            this._disableNavigationArrows();
        }

        this._seActiveElements();

        this._moveTrack();
    }
}

export default Slider;
